import userApi from '../../api/user'

const user = {
  namespaced: true,
  state: {
    user: null,
    fetchUserPromise: null,
    userRequest: null,
    userFormFilled: null,
    userFormPromise: null,
    userFormFilledV2: null,
    userFormPromiseV2: null,
    webcastTokenPromise: null,
    webcastToken: null,
    webcastTokenPromises: null,
    webcastTokens: {},
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload
    },
    setFetchPromise(state, payload) {
      state.fetchUserPromise = payload
    },
    setUserRequest(state, payload) {
      state.userRequest = payload
    },
    setUserFormFilled(state, payload) {
      state.userFormFilled = payload
    },
    setUserFormPromise(state, payload) {
      state.userFormPromise = payload
    },
    setUserFormFilledV2(state, payload) {
      state.userFormFilledV2 = payload
    },
    setUserFormPromiseV2(state, payload) {
      state.userFormPromiseV2 = payload
    },
    setWebcastTokenPromise(state, payload) {
      state.webcastTokenPromise = payload
    },
    setWebcastToken(state, payload) {
      state.webcastToken = payload
    },
    setWebcastTokens(state, payload) {
      state.webcastTokens[payload.short] = payload.webcasttoken
    },
    resetState(state) {
      state.user = null
      state.fetchUserPromise = null
      state.userRequest = null
      state.userFormFilled = null
      state.userFormPromise = null
      state.userFormFilledV2 = null
      state.userFormPromiseV2 = null
      state.webcastTokenPromise = null
      state.webcastToken = null
      state.webcastTokens = null
      state.webcastTokenPromises = null
    },
  },
  actions: {
    async loadUser({ commit, state }, payload) {
      const params = { ...payload }
      if (!params?.loadUserNew) {
        if (state.user !== null) {
          return state.user
        }
        if (state.fetchUserPromise) {
          return state.fetchUserPromise
        }
      }
      const fetchUserPromise = userApi.getManageUser().then((u) => {
        commit('setUser', u)
      })
      commit('setFetchPromise', fetchUserPromise)
      return fetchUserPromise
    },
    async loadWebcastToken({ commit, state }, payload) {

        if (state.webcastToken !== null) {
          return state.webcastToken
        }
        if (state.webcastTokenPromise) {
          return state.webcastTokenPromise
        }
  

        const fetchWebcastTokenrPromise = userApi.getUserWebcastToken().then((wt) => {
          commit('setWebcastToken', wt)
        })
        commit('setWebcastTokenPromise', fetchWebcastTokenrPromise)
        return fetchWebcastTokenrPromise
      
     

    },
    async userFormCompleteV2({ commit, state }, payload) {
      const params = { ...payload }
      if (!params?.loadUserCompleteNew) {
        if (state.userFormFilledV2 !== null) {
          return state.userFormFilledV2
        }
        if (state.userFormPromiseV2) {
          return state.userFormPromiseV2
        }
      }
      const fetchUserPromise = userApi.getUserFormCompleteV2().then((u) => {
        commit('setUserFormFilledV2', u)
      })
      commit('setUserFormPromiseV2', fetchUserPromise)
      return fetchUserPromise
    },

    async userFormComplete({ commit, state }, payload) {
      const params = { ...payload }
      if (!params?.loadUserCompleteNew) {
        if (state.userFormFilled !== null) {
          return state.userFormFilled
        }
        if (state.userFormPromise) {
          return state.userFormPromise
        }
      }
      const fetchUserPromise = userApi.getUserFormComplete().then((u) => {
        commit('setUserFormFilled', u)
      })
      commit('setUserFormPromise', fetchUserPromise)
      return fetchUserPromise
    },
    async saveUserProfile({ commit }, payload) {
      const UserModel = { ...payload }
      const fetchPromise = await userApi.saveUser(UserModel)

      commit('setUserRequest', fetchPromise)
      return fetchPromise
    },
    reset({ commit }) {
      commit('resetState')
    },
  },
  getters: {
    user(state) {
      return state.user
    },
    userId(state) {
      return state.user?.UserId
    },
    getWebcastToken(state) {
      return state.webcastToken
    },
    userFormFilled(state) {
      return state.userFormFilled
    },
    userFormFilledV2(state) {
      return state.userFormFilledV2
    },
  },
}

export default user
