<template>
  <section>
    <!-- The overlay -->
    <div id="accessDialog" class="overlay">
      <div class="contentDiv d-flex justify-content-center">
        <div class="content">
          <div
            class="text-right closebtnDiv"
            v-if="hcpConfig.showCloseButton"
          >
            <a
              :style="{ opacity: dialogStyle }"
              href="javascript:void(0)"
              class="closebtn"
              @click="close()"
            >&times;
            </a>
          </div>
          <template v-if="hcpDialogAccepted">
            <div class="hcp-modal-container">
              <p class="hcp-modal-title">
                {{ $t("VMX.HCP_modal_title") }}
              </p>
              <p class="hcp-modal-text" v-html="$t('VMX.HCP_modal_description')" />
              <input type="checkbox" v-model="isChecked" @change="handleCheckboxChange" />
              <label class="hcp-modal-checkbox-label">{{ $te("VMX.HCP_modal_label") ? $t("VMX.HCP_modal_label") : "I hereby agree to the terms of use."}}</label>
              <div class="hcp-modal-btns">
                <b-button
                  :disabled="!enableButtons"
                  variant="primary"
                  @click.prevent="accept()"
                >
                  <span>{{ $t("VMX.HCP_modal_yes_btn") }}</span>
                </b-button>
                <b-button
                  :disabled="!enableButtons"
                  v-if="hcpConfig.showDenyButton"
                  variant="outline-primary"
                  @click.prevent="hcpConfig.denyAsCloseButton ? close() : decline()"
                >
                  <span>{{ $t("VMX.HCP_modal_no_btn") }}</span>
                </b-button>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="hcp-modal-container">
              <p class="hcp-modal-title">
                {{ $t("VMX.HCP_modal_denied_title") }}
              </p>
              <p class="hcp-modal-text" v-html="$t('VMX.HCP_modal__denied_description')" />
              <div class="hcp-modal-btns">
                <b-button
                  variant="primary"
                  @click.prevent="close()"
                >
                  <span>{{ $t("VMX.HCP_modal_denied_yes_btn") }}</span>
                </b-button>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  data() {
    return {
      hcpConfigDefault: {
        showCloseButton: true,
        showDenyButton: true,
        denyAsCloseButton: false,
      },
      isChecked: false,
      enableButtons: false,
    }
  },
  props: {
    dialogStyle: {
      type: String,
      default: () => '',
    },
  },
  async created() {
    await this.loadUser()
  },
  computed: {
    ...mapGetters({
      user: 'user/user',
      history: 'instancesState/getHistory',
    }),
    hcpDialogAccepted() {
      if (localStorage.getItem('hcpDialogAccepted')) {
        return false
      }

      return true
    },
    hcpConfig() {
      return this.$store.getters['config/configForKey']('industry_config').config.Exhibition.hcpConfig || this.hcpConfigDefault
    },
  },
  methods: {
    ...mapActions({
      loadUser: 'user/loadUser',
      loadUserComplete: 'user/userFormComplete',
      saveProfile: 'user/saveUserProfile',
    }),
    close() {
      this.$emit('close')
      if (this.history.length > 1) {
        this.$router.go(-1)
      } else {
        this.$router.push({ name: 'HomePage' })
      }
    },
    handleCheckboxChange() {
      if (this.isChecked) {
        this.enableButtons = true
      } else this.enableButtons = false
    },
    async accept() {
      this.user.HealthCareProfessional = true
      const event = new CustomEvent('hcpDialogDone', {
      detail: {
        message: 'hcpDialogDone',
      },
    })
    window.dispatchEvent(event)
      await this.saveProfile(this.user).then(async (res) => {
        if (res.status === 200) {
          localStorage.setItem('hcpDialogAccepted', true)
          await this.loadUser({ loadUserNew: true })
          this.$emit('close')
        }
      })
    },
    async decline() {
      this.user.HealthCareProfessional = false
      await this.saveProfile(this.user).then(async (res) => {
        if (res.status === 200) {
          localStorage.setItem('hcpDialogAccepted', false)
          await this.loadUser({ loadUserNew: true })
          this.$emit('close')
          if (this.history.length > 1) {
            this.$router.go(-1)
          } else {
            this.$router.push({ name: 'HomePage' })
          }
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">

.overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  background-color: rgba(0, 13, 38, 0.99);
  overflow-x: hidden;
}
.contentDiv {
  position: relative;
  top: 25vh;
}
.content {
  width: 800px;
  background-color: white;
}
.content .title {
  font-size: 20px;
}
.content .text {
  font-size: 16px;
}
.overlay .closebtnDiv {
  margin: -10px 5px 0 0;
}
.overlay .closebtn {
  color: black;
  font-size: 30px;
  text-decoration: none;
}

.hcp-modal-container{
  padding: 30px 30px 30px 30px;

  .hcp-modal-title {
    font-size: 18px;
    font-weight: bold;
  }
  .btn-primary.disabled, .btn-primary:disabled {
    background-color: var(--primary) !important;
    color: var(--primary-contrast) !important;
    border-color: var(--primary-contrast) !important;
    opacity: .65;
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: var(--primary) !important;
  border-color: var(--primary) !important;
  background-color: var(--primary-contrast) !important;
  opacity: .65;
}
.hcp-modal-checkbox-label {
  margin-left: 5px;
}

}

@media screen and (max-height: 450px) {
}
</style>
