import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'
import config from '../config'

export default {
  install(Vue) {
    // use a new Vue instance as the interface for Vue components to receive/send SignalR events
    // this way every component can listen to events or send new events using this.$questionHub
    const notificationHub = new Vue()
    Vue.prototype.$notificationHub = notificationHub


    // Provide methods to connect/disconnect from the SignalR hub
    let connection = null
    let startedPromise = null
    let manuallyClosed = false

    const accountHub = new Vue()
    Vue.prototype.$accountHub = accountHub

    let aConnection = null
    let aStartedPromise = null
    let aManuallyClosed = false

    Vue.prototype.startSignalRAccount = (instance, eventShort, jwt, intmodal = 0, persist, uuid) => {
      const signalrHubUrl = `${config.getInstanceConfig(instance).signalrHub}`
      aConnection = new HubConnectionBuilder()
        .withUrl(`${signalrHubUrl}/singlelogin?eventShort=${eventShort}&jwt=${jwt}&intmodal=${intmodal}&persist=${persist}&uuid=${uuid}`)
        .configureLogging(LogLevel.Information)
        .build()

      aConnection.on('remoteLogout', (param) => {
        accountHub.$emit('remote-logout', { ...param })

        if (intmodal === 0) {
          aConnection.stop()
        }
      })

      aConnection.onclose(async (e) => {
        console.log('Connection Closed by Server: ' + e)
      })

      // Call connection.start() to establish the connection
      // but the client wont handle reconnecting for you!
      // Docs recommend listening onclose and handling it there.
      // This is the simplest of the strategies

      async function start() {
        try {
          // Set Event Handler for Connections closed by the server e.g. wrong paramter set
          await aConnection.start()
        } catch (err) {
          console.log("Connection Error: " + err)
          // setTimeout(() => start(), 5000);
        }
      }

      start()
    }

    Vue.prototype.startSignalR = (instance, groupName) => {
      const signalrHubUrl = `${config.getInstanceConfig(instance).signalrHub}`
      connection = new HubConnectionBuilder()
        .withUrl(`${signalrHubUrl}/notificationhub`)
        .configureLogging(LogLevel.Information)
        .build()

      // Forward hub events through the event, so we can listen for them in the Vue components
      connection.on('NotificationAdded', (notification) => {
        notificationHub.$emit('notification-added', { notification })
      })

      // Call connection.start() to establish the connection
      // but the client wont handle reconnecting for you!
      // Docs recommend listening onclose and handling it there.
      // This is the simplest of the strategies

      function start() {
        startedPromise = connection
          .start({ withCredentials: false })
          .then(() => connection.invoke('JoinGroup', groupName)).catch(console.error)
          .catch((err) => {
            console.error('Failed to connect with hub', err)
            return new Promise((resolve, reject) => setTimeout(() => start()
              .then(resolve)
              .catch(reject), 5000))
          })
        return startedPromise
      }

      connection.onclose(() => {
        if (!manuallyClosed) start()
      })

      // Start everything
      manuallyClosed = false
      start()
    }

    Vue.prototype.stopSignalR = () => {
      if (!startedPromise) return
      manuallyClosed = true
      return startedPromise.then(() => connection.stop()).then(() => { startedPromise = null })
    }

    Vue.prototype.stopSignalRAccount = () => {
      if (!aStartedPromise) return
      aManuallyClosed = true
      return aStartedPromise.then(() => aConnection.stop()).then(() => { aStartedPromise = null })
    }

    // Provide methods for components to send messages back to server
    // Make sure no invocation happens until the connection is established
    notificationHub.notificationClosed = () => {
      if (!startedPromise) return
      return startedPromise.then(() => connection.invoke('CloseNotification', groupName)).catch(console.error)
    }

    accountHub.accountClosed = () => {
      if (!aStartedPromise) return
      return aStartedPromise.then(() => aConnection.invoke('CloseConnection', groupName)).catch(console.error)
    }
  },
}
