import VueCookies from 'vue-cookies'
import Vue from 'vue'
import dayjs from 'dayjs'

import store from '~/store'

import userState from '~/states/user'
import utilsComponents from 'm-anage.com.vmx-components/src/utilities/'


Vue.use(VueCookies)

// splitt policies into deny and allow policies
function getDenyAllowPolicies(type, id) {
  const useAppAccessMatrix = 'useAppAccessMatrix' in store.getters['config/configForKey']('app_config').config[0].defaults ? store.getters['config/configForKey']('app_config').config[0].defaults.useAppAccessMatrix : false
  let policies
  const policyGetter = (localStorage.getItem('isApp') === 'true') && useAppAccessMatrix ? store.getters['config/configForKey']('access_policies_app') : store.getters['config/configForKey']('access_policies')
  if (policyGetter) {
    const {
      policies: policiesConfig,
      policies_groups: policiesGroups,
    } = policyGetter
    // TODO: create an interceptor for when the config is an empty object
    policies = policiesConfig.reduce((acc, val) => {
      const { PoliciesIds, ...restPol } = val
      acc.push({
        ...restPol,
        Policies: [
          ...PoliciesIds.map((policyId) => policiesGroups.find((item) => item.id === policyId)),
        ],
      })
      return acc
    }, [])
  } else {
    const config = store.getters['config/configForKey']('app_config').config[0]
    policies = config.policies
  }
  const denyAllowRules = {
    deny: [],
    allow: [],
  }

  if (policies) {
    const rules = policies.filter((policy) => {
      if (Array.isArray(policy.Identifier)) {
        return (
          policy.Type?.toLowerCase() === type?.toLowerCase()
          && policy.Identifier?.some((x) => x.toLowerCase() === id?.toLowerCase())
        )
      }
      return (
        policy.Type?.toLowerCase() === type?.toLowerCase()
        && policy.Identifier?.toLowerCase() === id?.toLowerCase()
      )
    })
    if (rules.length > 1) {
      // TODO: check if for is really needed
      console.log(`WARNING: multiple policy for type:${type}id:${id}`)
    }
    if (rules.length > 0) {
      // TODO: check if for is really needed
      for (let i = 0, len = rules[0].Policies.length; i < len; i += 1) {
        if (rules[0].Policies[i].Effect.indexOf('Allow') > -1) {
          denyAllowRules.allow.push(rules[0].Policies[i])
        }

        if (rules[0].Policies[i].Effect.indexOf('Deny') > -1) {
          denyAllowRules.deny.push(rules[0].Policies[i])
        }
      }
    }
  }
  return denyAllowRules
}

// get usser & tickets
function getUser() {
  let user = []
  let hcp = false
  let participantType = ''
  let participantTypeValid = false
  let societyMemberType = ''
  let country = ''
  let userTickets = []
  const todayDate = null
  const storeUserData = store.getters['userTicket/userTicket']
  let userLoggedIn = false
  if (storeUserData) {
    userTickets = storeUserData
  }
  hcp = JSON.parse(Vue.$cookies.get('hcp'))
  if (userTickets.length > 0) {
    hcp = userTickets[0].EnableHCP
    participantType = userTickets[0].ParticipantTypeName
    participantTypeValid = userTickets[0].ParticipantTypeValid
    societyMemberType = userTickets[0].SocietyMemberType
    country = userTickets[0].Country
  }

  if (userState.getUser(store.getters['instancesState/getInstance'])) {
    userLoggedIn = true
  }

  user = {
    hcp,
    participantTypeValid,
    participantType,
    societyMemberType,
    country,
    tickets: userTickets,
    todayDate,
    loggedIn: userLoggedIn,
  }
  return user
}

function formatTicketDate(date) {
  return dayjs(date)
}



let accessDenyReasonsObj = {}

function resetADR() {
  accessDenyReasonsObj = []
}
// evaluate each ticket and policy
function evalPolicy(policy, checkId, effect) {
  const user = getUser()
  let result = false
  const arr = []
  const accessDenyReasons = []
  let userPaidTicket = []
  let userValidTicket = []
  let userNoCommentDeny = []
  let userNoMessagesDeny = []
  let userDenyNetworking = []
  let paid = false
  let commentDeny = false
  let messageDeny = false
  let networkingDeny = false
  let valid = false
  let vIdValid = false
  let elEval = null
  let paidEval = false
  let hcpVal = null
  let loggedIn = false
  let lLoggedInVal = null
  let userVisitorId = []
  let timezone = utilsComponents.eventTimezone()
  let serverTime = ''

  if (store.getters['instancesState/getSuperUser']) {
    result = true
  } else {
    policy.Requirements.forEach((r) => {
      // requirement concatenation bug fixed by Florian Oberleitner, to be reviewed for further bugs and reset values
      // TODO: at this position all result values need to be reset for each requirement iteration
      paid = false
      switch (r.PrincipalField) {
        case 'SocietyMemberType':
          if(!r.Values.includes(user.societyMemberType)) {
            accessDenyReasons.push(r.PrincipalField)
          }
          arr.push(r.Values.includes(user.societyMemberType))
          break
        // CC: dm 10.11.2020 - https://jmarquardt.atlassian.net/browse/MANAGE-14754
        case 'EventParticipantType':
          if(!r.Values.includes(user.participantType)) {
            accessDenyReasons.push(r.PrincipalField)
          }
          arr.push(r.Values.includes(user.participantType))
          break
        // CC: dm 10.11.2020 - https://jmarquardt.atlassian.net/browse/MANAGE-14754
        case 'ParticipantTypeCheck':
          if(!r.Values.includes(user.participantType)) {
            accessDenyReasons.push(r.PrincipalField)
          }

          if(!user.participantTypeValid) {
            accessDenyReasons.push(r.PrincipalField)
          }
          arr.push(user.participantTypeValid)
          break
         // CC: mc: 17.01.2024 https://jmarquardt.atlassian.net/browse/JMT-15386
        case 'UserCountry':
          if(!r.Values.includes(user.country)) {
            accessDenyReasons.push(r.PrincipalField)
          }
          arr.push(r.Values.includes(user.country))
          break

        case 'TimeAccess':
          dayjs.tz.setDefault(timezone)
          serverTime = store.getters['serverTime/getTime']
          if (serverTime) {
            serverTime = dayjs.tz(dayjs(serverTime), timezone)
          } else {
            serverTime = dayjs().tz()
          }
          if(effect === 'Allow') {
            if(!dayjs.tz(r.Values[0], timezone).isAfter(serverTime)) {
              accessDenyReasons.push(r.PrincipalField)
            }
          }
          if(effect === 'Deny') {
            if(dayjs.tz(r.Values[0], timezone).isAfter(serverTime)) {
              accessDenyReasons.push(r.PrincipalField)
            }
          }

          arr.push(dayjs.tz(r.Values[0], timezone).isAfter(serverTime))

          break
        case 'ExhibitorExclusive':
          const symposiaList = store.getters['ILPSymposiaList/list']
          const currentSession = symposiaList.find((item) => item.Id === Number(checkId))
          const { CompanyAccountId } = currentSession
          const { tickets } = user
          const isInvitedByCurrentExhibitor = tickets.some((item) => item.CompanyAccountId === CompanyAccountId)
          if(!isInvitedByCurrentExhibitor) {
            accessDenyReasons.push(r.PrincipalField)
          }
          arr.push(isInvitedByCurrentExhibitor)

          break
        case 'EventTicketPaid':
          for (const ticket of user.tickets) { // TODO: check if for is really needed
            if (ticket.IsPayed) {
              paidEval = true
              break
            }
          }
          if(!paidEval) {
            accessDenyReasons.push(r.PrincipalField)
          }
          arr.push(paidEval)
          break
        case 'Hcp':
        case 'UserHcp':
          elEval = false
          if (r.Values) {
            hcpVal = r.Values[0]
          }
          if (hcpVal === null || hcpVal === true) {
            if (user.hcp) {
              elEval = true
            }
          } else if (hcpVal === false) {
            if (user.hcp === false) {
              elEval = true
            }
          }
          if(!elEval) {
            accessDenyReasons.push(r.PrincipalField)
          }
          arr.push(elEval)
          break
        case 'UserLoggedIn':
          loggedIn = false
          if (r.Values) {
            lLoggedInVal = (r.Values[0] === 'true')
          }
          if (lLoggedInVal === null || lLoggedInVal === true) {
            if (user.loggedIn) {
              loggedIn = true
            }
          } else if (lLoggedInVal === false) {
            if (user.loggedIn === false) {
              loggedIn = true
            }
          }
          if(!loggedIn) {
            accessDenyReasons.push(r.PrincipalField)
          }
          arr.push(loggedIn)
          break
        case 'EventTicketNameValid':
          userValidTicket = user.tickets.filter((ticket) => ticket.IsValid === true)
          for (const ticket of userValidTicket) {
            if (r.Values.includes(ticket.TicketTypeName)) {
              valid = true
              break
            }
          }
          if(!valid) {
            accessDenyReasons.push(r.PrincipalField)
          }
          arr.push(valid)
          break
        case 'EventTicketNamePaid':
          userPaidTicket = user.tickets.filter((ticket) => ticket.IsPayed === true)

          for (const ticket of userPaidTicket) { // TODO: check if for is really needed
            if (r.Values.includes(ticket.TicketTypeName)) {
              paid = true
              break
            }
          }
          if(!paid) {
            accessDenyReasons.push(r.PrincipalField)
          }
          arr.push(paid)
          break
        case 'EventTicketNameForComments':
          userNoCommentDeny = user.tickets.filter((ticket) => ticket.IsPayed === true)
          for (const ticket of userNoCommentDeny) { // TODO: check if for is really needed
            if (r.Values.includes(ticket.TicketTypeName)) {
              commentDeny = true
              break
            }
          }
          if(!commentDeny) {
            accessDenyReasons.push(r.PrincipalField)
          }
          arr.push(commentDeny)
          break
        case 'EventTicketNameForMessages':
          userNoMessagesDeny = user.tickets.filter((ticket) => ticket.IsPayed === true)
          for (const ticket of userNoMessagesDeny) { // TODO: check if for is really needed
            if (r.Values.includes(ticket.TicketTypeName)) {
              messageDeny = true
              break
            }
          }
          arr.push(messageDeny)
          break
        case 'EventTicketNetworking':
          userDenyNetworking = user.tickets.filter((ticket) => ticket.IsPayed === true)
          for (const ticket of userDenyNetworking) { // TODO: check if for is really needed
            if (r.Values.includes(ticket.TicketTypeName)) {
              networkingDeny = true
              break
            }
          }
          if(!networkingDeny) {
            accessDenyReasons.push(r.PrincipalField)
          }
          arr.push(networkingDeny)
          break
        case 'EventTicketNamePaidAndTime':
          userPaidTicket = user.tickets.filter((ticket) => (
            ticket.IsPayed === true
            && ticket.TicketFrom
            && ticket.TicketTo
          ))

          // TODO: check if for is really needed
          for (const ticket of userPaidTicket) {
            if (r.Values.includes(ticket.TicketTypeName)) {
              const dateNow = dayjs()

              const ticketFrom = formatTicketDate(ticket.TicketFrom)
              const ticketTo = formatTicketDate(ticket.TicketTo)

              if (dateNow.diff(ticketFrom) >= 0 && dateNow.diff(ticketTo) <= 0) {
                paid = true
                break
              }
            }
          }
          if(!paid) {
            accessDenyReasons.push(r.PrincipalField)
          }
          arr.push(paid)
          break
        case 'VisitorIdIncluded':
          userVisitorId = user.tickets.filter((ticket) => ticket.VisitorId)

          for (const vId of userVisitorId) {
            if (r.Values.includes(vId.VisitorId)) {
              vIdValid = true
              break
            }
          }
          if(!vIdValid) {
            accessDenyReasons.push(r.PrincipalField)
          }
          arr.push(vIdValid)
          break
      }

    })
    switch (policy.ConditionType) {
      case 'All':
        if (!arr.includes(false)) {
          result = true
        }
        break
      case 'Any':
        if (arr.includes(true)) {
          result = true
        }
        break
    }
  }

  accessDenyReasonsObj[policy.Name] = accessDenyReasons
  localStorage.setItem('accessDenyReasons', JSON.stringify(accessDenyReasonsObj))
  return result
} 

export default {
  accessControl(type, id) {
    const policies = getDenyAllowPolicies(type, id)
    if (policies.deny.length === 0 && policies.allow.length === 0) {
      console.log('Access Control: allowed - no rules assigned')
      return true
    }

    if (policies.deny && policies.deny.length > 0) {
      // TODO: check if for is really needed
      for (let i = 0, len = policies.deny.length; i < len; i += 1) {
        if (evalPolicy(policies.deny[i], id, policies.deny[i].Effect)) {
          console.log(`policy: ${policies.deny[i].Name}`, `, version: ${policies.deny[i].Version}`)
          console.log('Access Control: denied')
          if (store.getters['instancesState/getSuperUser']) return true
          return false
        }
      }

    }

    if (policies.deny.length > 0 && policies.allow.length === 0) {
      console.log('Access Control: allowed - no allow rules assigned')
      return true
    }

    if (policies.allow && policies.allow.length > 0) {
      // TODO: check if for is really needed
      for (let i = 0, len = policies.allow.length; i < len; i += 1) {
        if (evalPolicy(policies.allow[i], id, policies.allow[i].Effect)) {
          console.log(`policy: ${policies.allow[i].Name}`, `, version: ${policies.allow[i].Version}`)
          console.log('Access Control: allowed')
          return true
        }
      }
    }

    if (policies > 0) {
      policies.deny.forEach((policy) => {
        console.log(`policy: ${policy.Name}`, `, version: ${policy.Version}`)
      })
      policies.allow.forEach((policy) => {
        console.log(`policy: ${policy.Name}`, `, version: ${policy.Version}`)
      })
    }
    console.log('Access Control: denied')
    return false
  },

  accessControlSession(id) {
    return this.accessControl('Session', id)
  },

  accessControlSessionRecording(id) {
    return this.accessControl('SessionRecording', id)
  },

  accessControlPages(page) {
    return this.accessControl('Page', page)
  },

  accessControlUrl(url) {
    return this.accessControl('Url', url)
  },

  accessControlKey(key) {
    return this.accessControl('Key', key)
  },

  accessDeniedDialog(data) {
    const event = new CustomEvent('accessDenied', {
      detail: {
        message: 'accessDenied',
        info: data,
      },
    })
    window.dispatchEvent(event)
  },
}
