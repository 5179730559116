const sessionRecordingData = {
  namespaced: true,
  state: {
    jumptimes: null,
  },
  mutations: {
    setJumptimes(state, payload) {
      state.jumptimes = payload
    },
  },
  actions: {
    async addJumptimes({ commit }, payload) {
      commit('setJumptimes', payload)
    },
  },
  getters: {
    getJumptimes(state) {
      return state.jumptimes
    },
  },
}
export default sessionRecordingData
