import Vue from 'vue'
import VueCookies from 'vue-cookies'
import store from '~/store'
import apiBase from './base'

Vue.use(VueCookies)

async function getManageUser() {
  const eventId = store.getters['instancesState/getEventId']
  let url = ''
  url = `${store.getters['instancesState/getApiBaseUrl']}/api/Core/User?eventid=${eventId}`
  const response = await apiBase.get(url)

  if (response?.status === 200) {
    return response.data
  }
  return null
}

async function blockUser() {

  const userAgent = navigator.userAgent;

  // Function to determine the device type
  const getDeviceType = (ua) => {
      if (/iPhone/.test(ua)) {
          return "iPhone";
      } else if (/iPad/.test(ua)) {
          return "iPad";
      } else if (/iPod/.test(ua)) {
          return "iPod";
      } else if (/Android/.test(ua) && !/Mobile/.test(ua)) {
          return "Android Tablet";
      } else if (/Android/.test(ua)) {
          return "Android Phone";
      } else if (/Windows Phone/.test(ua)) {
          return "Windows Phone";
      } else if (/Macintosh/.test(ua)) {
          return "Mac";
      } else if (/Windows/.test(ua)) {
          return "Windows PC";
      } else if (/Linux/.test(ua)) {
          return "Linux PC";
      } else {
          return "Other";
      }
  }

  const getBrowserInfo = (ua) => {
    let browserName = "Unknown";
    let fullVersion = "Unknown";

    if (/Chrome\/(\S+)/.test(ua)) {
        browserName = "Chrome";
        fullVersion = ua.match(/Chrome\/(\S+)/)[1];
    } else if (/Safari\/(\S+)/.test(ua) && !/Chrome/.test(ua)) {
        browserName = "Safari";
        fullVersion = ua.match(/Version\/(\S+)/)[1];
    } else if (/Firefox\/(\S+)/.test(ua)) {
        browserName = "Firefox";
        fullVersion = ua.match(/Firefox\/(\S+)/)[1];
    } else if (/MSIE (\d+\.\d+);/.test(ua)) {
        browserName = "Internet Explorer";
        fullVersion = ua.match(/MSIE (\d+\.\d+);/)[1];
    } else if (/Trident\/.*rv:(\d+\.\d+)/.test(ua)) {
        browserName = "Internet Explorer";
        fullVersion = ua.match(/Trident\/.*rv:(\d+\.\d+)/)[1];
    } else if (/Edg\/(\S+)/.test(ua)) {
        browserName = "Microsoft Edge";
        fullVersion = ua.match(/Edg\/(\S+)/)[1];
    } else if (/Opera\/(\S+)/.test(ua)) {
        browserName = "Opera";
        fullVersion = ua.match(/Opera\/(\S+)/)[1];
    } else if (/OPR\/(\S+)/.test(ua)) {
        browserName = "Opera";
        fullVersion = ua.match(/OPR\/(\S+)/)[1];
    }

    return {
        name: browserName,
        version: fullVersion
    };
  }

  const deviceType = getDeviceType(userAgent);
  const browserNameAndVersion = `${getBrowserInfo(userAgent).name}${getBrowserInfo(userAgent).version}`
  const eventShort = store.getters['instancesState/getEvent']
  let url = `${store.getters['instancesState/getApiBaseUrl']}/api/User/BlockUser?shortname=${eventShort}&device=${deviceType}&browser=${browserNameAndVersion}'`
  const response = await apiBase.post(url)

  if (response?.status === 200) {
    return response.data
  }
  return null
}

async function getUserFormComplete() {
  const eventId = store.getters['instancesState/getEventId']
  let url = ''
  url = `${store.getters['instancesState/getApiBaseUrl']}/api/Core/UserComplete?eventid=${eventId}`
  const response = await apiBase.get(url)
  if (response?.status === 200) {
    return response.data
  }
  return null
}

async function getUserFormCompleteV2() {
  const event = store.getters['instancesState/getEvent']
  const data = {  
    "context": "event",
    "shortName": event,
    "role": "User",
    "withCQCheck": true
  }
  let url = ''
  url = `${store.getters['instancesState/getApiBaseUrl']}/v2/Core/CheckPermissionsForRole`
  const response = await apiBase.post(url, data)
  if (response?.status === 200) {
    return response.data
  }
  return null
}

async function getUserWebcastToken(short) {
  const eventShort = short || store.getters['instancesState/getEvent']
  const url = `${store.getters['instancesState/getApiBaseUrl']}/api/Core/BearerTokenWebcastAccess?eventshort=${eventShort}`
  const response = await apiBase.post(url)
  if (response?.status === 200) {
    return response.headers.bearertoken
  }
  return null
}

async function saveUser(UserModel) {
  const eventId = store.getters['instancesState/getEventId']
  const url = `${store.getters['instancesState/getApiBaseUrl']}/api/Core/User?eventid=${eventId}`
  const {
    Firstname,
    Surname,
    TitleId,
    MaidenName,
    Initials,
    Gender,
    GenderExtended,
    Birthday,
    PlaceOfBirth,
    DistrictOfBirth,
    NationalityId,
    CountryResidenceId,
    Profession,
    CountryPracticeId,
    HealthCareProfessional,
    Efn,
    CMEId,
    SelectedInterests,
    Activity,
    VAT,
    Biography,
  } = UserModel

  const Contact = {
    CompanyName: UserModel.CompanyName ? UserModel.CompanyName : UserModel.Contact?.CompanyName,
    Institute: UserModel.Institute ? UserModel.Institute : UserModel.Contact?.Institute,
    Department1: UserModel.Department1 ? UserModel.Department1 : UserModel.Contact?.Department1,
    Department2: UserModel.Department2 ? UserModel.Department2 : UserModel.Contact?.Department2,
    Street: UserModel.Street ? UserModel.Street : UserModel.Contact?.Street,
    City: UserModel.City ? UserModel.City : UserModel.Contact?.City,
    ZipCode: UserModel.ZipCode ? UserModel.ZipCode : UserModel.Contact?.ZipCode,
    CountryId: UserModel.CountryId ? UserModel.CountryId : UserModel.Contact?.CountryId,
    Phone: UserModel.Phone ? UserModel.Phone : UserModel.Contact?.Phone,
    Phone2: UserModel.Phone2 ? UserModel.Phone2 : UserModel.Contact?.Phone2,
    Mobil: UserModel.Mobil ? UserModel.Mobil : UserModel.Contact?.Mobil,
    Fax: UserModel.Fax ? UserModel.Fax : UserModel.Contact?.Fax,
    Www: UserModel.Www ? UserModel.Www : UserModel.Contact?.Www,
  }

  const data = {
    Firstname,
    Surname,
    TitleId,
    MaidenName,
    Initials,
    Gender,
    GenderExtended,
    Birthday,
    PlaceOfBirth,
    DistrictOfBirth,
    NationalityId,
    HealthCareProfessional,
    CountryResidenceId,
    Profession,
    CountryPracticeId,
    Efn,
    CMEId,
    SelectedInterests,
    Activity,
    VAT,
    Biography,
    Contact,
  }

  const response = await apiBase.post(url, data)

  if (response?.status === 200) {
    return response
  }

  return null
}

async function getVmUser() {
  const eventId = store.getters['instancesState/getEventId']
  let url = ''
  url = `${store.getters['instancesState/getApiBaseUrl']}/api/Core/GetVMToken/?eventid=${eventId}`
  const response = await apiBase.get(url)

  if (response && response.status === 200) {
    return response.data
  }
  return null
}

async function getUserTickets() {
  const eventId = store.getters['instancesState/getEventId']
  const externalAuthCode = Vue.$cookies.get('externalAuthorizationCode') ? Vue.$cookies.get('externalAuthorizationCode') : ''
  // eslint-disable-next-line max-len
  const url = `${store.getters['instancesState/getApiBaseUrl']}/api/modules/BookedTickets?eventid=${eventId}&externalAuthorizationCode=${externalAuthCode}`
  // https://vmx-dev.m-anage.com/home/staging/eha2023/en-GB?externalAuthorizationCode= refid 597 %7C apppin ZRA5X1
  const response = await apiBase.get(url)

  if(response) {
    
    Vue.$cookies.remove('externalAuthorizationCode')
  }

  if (response?.status === 200) {
    return response.data
  }

  if (response?.status === 204) {
    return response
  }

  if (response?.status === 410) {
    return response
  }

  if (response === undefined) {
    const error = [...store.getters['Error/errors']]
    if (error[error.length - 1]?.response) {
      return error[error.length - 1].response
    }
  }
  return null
}

async function getPagesWithTicketCheck(eventName) {
  let localConfigUrl = null

  if (eventName) {
    try {
      // eslint-disable-next-line global-require
      localConfigUrl = require(`../../public/customerModel/${eventName}/pagesWithTicketCheck.json`)
      if (localConfigUrl && localConfigUrl.pages) {
        return localConfigUrl.pages
      }
      // const localResponse = await apiBase.get(localConfigUrl);
      // if (localResponse && localResponse.status === 200) {
      //   return localResponse.data.pages
      // }
    } catch (e) {
      return []
    }
  } else {
    return []
  }
  return null
}

export default {
  getPagesWithTicketCheck,
  getManageUser,
  getUserTickets,
  getVmUser,
  saveUser,
  getUserFormComplete,
  getUserFormCompleteV2,
  getUserWebcastToken,
  blockUser,
}
