<template>
  <component
    :is="mobileView ? 'mobile-container' : 'container'"
    :breakpoint="breakpoint"
    class="bannerMenu__my-area__container"
  >
    <component
      :is="mobileView ? 'mobile-menu-div' : 'desktop-menu-div'"
      class="bannerMenu__my-area__container-flex"
    >
      <template v-if="mobileView && user">
        <user-info />
      </template>

      <template v-if="loginStatus && myCongress">
        <template v-if="mobileView && myCongressItems.length">
          <dropdown-menu
            :items="myCongressItems"
            :width="width"
          >
            <my-congress-text class="bannerMenu-my-congress-section">
              <icon-container :breakpoint="breakpoint" class="bannerMenu-my-congress-section-icon">
                <font-awesome-icon :icon="['fa', 'user']" />
              </icon-container>
              {{ $t("VMX.nav_my_congress") }}
            </my-congress-text>
          </dropdown-menu>
        </template>
        <template v-else>
          <router-link :to="{ name: 'MyAreaPage' }">
            <font-awesome-icon :icon="['fa', 'user']" />
            {{ $t("VMX.nav_my_congress") }}
          </router-link>
        </template>
      </template>
      <component
        v-for="(item, index) in menuItems"
        :key="index"
        :hidden="!checkIsComponentTimeActiveHeader(item)"
        :is="tag(item)"
        v-bind="tagProps(item)"
      >
        <icon-container
          :breakpoint="breakpoint"
          v-if="item.icon"
        >
        <!-- https://distribute.m-anage.com/from.storage?filecore=9Id5LfmSdX38MrsKKTwXUA -->
          <template v-if="'iconImagePath' in item">
            <img
              class="myarea-section__icon-image-path"
              :src="item.iconImagePath"
            />
          </template>
          <template v-else-if="item.icon">
            <font-awesome-icon :icon="icon(item.icon)" />
          </template>
        </icon-container>
        <banner-menu-items>
          {{ item.name }}
        </banner-menu-items>
      </component>
      <template v-if="mobileView && customMenuItems.length">
        <div class="bannerMenu__customMenuItem-container">
          <item-div
            class="vmx-header__nav-custom-item-container"
            v-for="(item, idx) in customMenuItems"
            :hidden="!checkIsComponentTimeActiveHeader(item)"
            :key="`${idx}-custom-nav-item`"
            :breakpoint="breakpoint"
            v-bind="navItemProps(item)"
            :active="navItemProps(item).active ? 'rgba(255, 255, 255, 0.3)' : null"
            :ref="`menu-item-${idx}`"
          >
            <template v-if="item.subMenu">
              <dropdown-menu
                :items="item.subMenu"
                :width="width"
                :item-nr="idx"
                @subActive="setActive"
              >
                <span>
                  <icon-wrapper :breakpoint="breakpoint">
                    <template v-if="item.icon">
                      <font-awesome-icon :icon="icon(item.icon)" />
                    </template>
                  </icon-wrapper>
                  {{ item.name }}
                </span>
              </dropdown-menu>
            </template>
            <template v-else>
              <component
                :is="tag(item)"
                v-bind="tagProps(item)"
                class="_nav-item"
              >
                <icon-wrapper :breakpoint="breakpoint">
                  <template v-if="item.icon">
                    <font-awesome-icon :icon="icon(item.icon)" />
                  </template>
                </icon-wrapper>
                {{ item.name }}
              </component>
            </template>
          </item-div>
        </div>
      </template>
      <template v-if="loginStatus">
        <a
          href
          @click.prevent="logout"
          class="vmx-header__logout-button"
        >
          {{ $t("VMX.user_logout") }}
        </a>
      </template>
      <template v-else>
        <a
          href
          @click.prevent="login"
          class="vmx-header__login-button"
        >
          {{ $t("VMX.user_login") }}
        </a>
      </template>
    </component>
  </component>
</template>

<script>
import styled from '@u-rogel/vue-emotion'
import userState from '~/states/user'
import componentIsActiveMixins from '~/mixins/componentIsActiveMixins'
import tagMixins from '~/mixins/tagMixins'
import { mapGetters } from 'vuex'
import config from '~/config'
import utils from '~/utilities/utils'
import headerMixins from '~/mixins/headerMixins'
import UserInfo from './UserInfo.vue'
import DropdownMenu from './DropdownMenu.vue'
import userApi from '~/api/user'

const Container = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: ${({ breakpoint }) => breakpoint || 1024}px) {
    display: none;
  }

`({
  breakpoint: Number,
})

const MobileContainer = styled.div`

`()

const BannerMenuItems = styled.span`

`()

const MenuDiv = styled.div`
  display: flex;
  flex-direction: column;
  a, a:hover {
    text-decoration: none;
  }
`()

const DesktopMenuDiv = styled(MenuDiv)`
  align-items: flex-end;
  white-space: nowrap;
  height: 70px;
  font-weight: bold;
  font-size: 14px;
  justify-content: center;
  line-height: 19px;
  gap: .125rem;
  a, a:hover {
    color: var(--header-banner-menu-text);
    background-color: var(--header-banner-menu-bg);
  }
  svg {
    display: none;
  }
`()

const MobileMenuDiv = styled(MenuDiv)`

  a, a:hover {
    color: var(--header-navbar-bg-contrast);
  }
  > a, div:not(:first-child):not(.bannerMenu__customMenuItem-container) {
    color: var(--header-navbar-bg-contrast);
    padding: 10px 20px 10px 20px;
  }

  .vmx-header__logout-button, .vmx-header__login-button {
    margin-left: 25px;
  }
  .bannerMenu__customMenuItem-container {
    display: flex;
    flex-direction: column;
    .vmx-header__nav-custom-item-container {
      padding: 10px 20px 10px 20px;
    }
  }
`()

const IconContainer = styled.span`
  @media (min-width: ${({ breakpoint }) => breakpoint || 1024}) {
    display: none;
  }
  display: inline-block;
  width: 21px;
  color: var(--header-navbar-bg-contrast);
  svg {
    margin-right: 5px;
  }
`({
  breakpoint: Number,
})

const MyCongressText = styled.span`
  overflow: hidden;
  white-space: nowrap;
`()

const ItemDiv = styled.div`
  .router-link-exact-active.active {
    background-color: rgba(255, 255, 255, 0.3)
  }
  background-color: ${({ active }) => active};
  //&.sub-active {
  //  background-color: rgba(255, 255, 255, 0.2)
  //}
  @media (min-width: ${({ breakpoint }) => breakpoint + 1 || 1025}px) {
    height: 100%;
    display: flex;
    align-items: center;
    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }

  > :nth-child(1) {
    color: var(--header-navbar-bg-contrast);
    white-space: nowrap;
    @media (max-width: 1366px) {
      display: block;
    }
    @media (max-width: ${({ breakpoint }) => breakpoint || 1024}px) {
      display: block;
      &:hover {
        text-decoration: none;
      }
    }
  }

  @media (max-width: ${({ breakpoint }) => breakpoint || 1024}px) {
    padding: 0 18px 0 20px;
    text-decoration: none;
    transition: 0.3s;
  }
  @media (min-width: ${({ onlyInMobile }) => onlyInMobile + 1}px) {
    display: none;
  }
`({
  breakpoint: Number,
  active: String,
  onlyInMobile: Number,
})

const IconWrapper = styled.span`
  @media (min-width: ${({ breakpoint }) => breakpoint || 1024}px) {
    display: none;
  }
  display: inline-block;
  width: 21px;
  color: var(--header-navbar-bg-contrast);
  svg {
    margin-right: 5px;
  }

`({
  breakpoint: Number,
})

export default {
  name: 'BannerMenu',
  inject: {
    TrackingUtil: {
      type: Function,
    },
  },
  mixins: [
    tagMixins,
    headerMixins,
    componentIsActiveMixins.componentProps,
    componentIsActiveMixins.method,
  ],
  components: {
    Container,
    MobileContainer,
    MobileMenuDiv,
    UserInfo,
    IconContainer,
    DropdownMenu,
    BannerMenuItems,
    DesktopMenuDiv,
    MyCongressText,
    ItemDiv,
    IconWrapper,
  },
  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
    mobileView: {
      type: Boolean,
      default: false,
    },
    myCongress: {
      type: Boolean,
      default: true,
    },
    width: {
      type: Number,
      default: 0,
    },
    maxCongressItems: {
      type: Number,
      default: 2,
    },
    myCongressItems: {
      type: Array,
      default() {
        return []
      },
    },
    customMenuItems: {
      type: Array,
      default() {
        return []
      },
    },
  },
  computed: {
    ...mapGetters({
      instance: 'instancesState/getInstance',
      event: 'instancesState/getEvent',
      language: 'instancesState/getLanguage',
      configForKey: 'config/configForKey',
      user: 'user/user',
    }),
    menuItems() {
      return this.items?.slice(0, this.maxCongressItems)
    },
    loginStatus() {
      return !!userState.getUser(this.instance)
    },
    backendConfig() {
      return this.configForKey('app_config').config[0]
    },
    externalAuth() {
      if (this.backendConfig.defaults?.externalAuth) {
        return this.backendConfig.defaults.externalAuth.externalLogin
      }
      return false
    },
    signalRConnection() {
      return this.configForKey('app_config').config[0].AccountHub?.signalRConnection ? this.configForKey('app_config').config[0].AccountHub.signalRConnection : false
    },
    signalRConnectionAmount() {
      return this.configForKey('app_config').config[0].AccountHub?.signalRConnectionAmount ? this.configForKey('app_config').config[0].AccountHub.signalRConnectionAmount : 0
    },
    openSignalRModal() {
      return this.configForKey('app_config').config[0].AccountHub?.openSignalRModal ? this.configForKey('app_config').config[0].AccountHub.openSignalRModal : false
    },
  },
  created() {
    if (this.signalRConnection) {
      this.$accountHub.$on('remote-logout', async (params) => {
        if ('uuid' in params && 'modal' in params && this.openSignalRModal && userState.getUser(this.instance).uuid === params.uuid && params.modal) {
          this.emitOpenSignalRModal()
        } else if (this.openSignalRModal) {
          await userApi.blockUser()
          this.logout()
        } else {
          this.logout()
        }
      })
    }
    this.$root.$on('logout-user-device', async () => {
      await userApi.blockUser()
      await this.$accountHub.startSignalRAccount(this.instance, this.event, userState.getUser(this.instance).accessToken, this.signalRConnectionAmount, false, userState.getUser(this.instance).uuid)
      this.emitCloseSignalRModal()
      this.logout()
    })

    this.$root.$on('continue-user-device', async () => {
      await this.$accountHub.startSignalRAccount(this.instance, this.event, userState.getUser(this.instance).accessToken, this.signalRConnectionAmount, true, userState.getUser(this.instance).uuid)
      this.emitCloseSignalRModal()
    })
  },
  mounted() {
    if (userState.getUser(this.instance)?.accessToken && this.signalRConnection) {
      this.$accountHub.startSignalRAccount(this.instance, this.event, userState.getUser(this.instance).accessToken, this.signalRConnectionAmount, null, userState.getUser(this.instance).uuid)
    }
    // window.addEventListener('pagesForPagAuth', this.receiveMessage)
  },
  // beforeDestroy() {
  //   window.removeEventListener('pagesForPagAuth', this.receiveMessage)
  // },
  methods: {
    parseJwt(token) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      return JSON.parse(jsonPayload);
    },
    // async receiveMessage(event) {
    //   if (event?.detail?.message === 'pagesForPagAuth') {
    //     if(userState.getUser(this.instance) === null) {
    //       setTimeout(() => {
    //         window.location.reload()
    //       }, 1000)
    //     }
    //   }
    // },
    emitOpenSignalRModal() {
      this.$root.$emit('open-signalr-logout-modal')
    },
    emitCloseSignalRModal() {
      this.$root.$emit('close-signalr-logout-modal')
    },
    tracking(name) {
      if (utils.advancedTracking()) {
        this.TrackingUtil.trackEvent(
          'HeaderMenu',
          'Click',
          utils.advancedTrackingFormat(
            this.user.UserId,
            'Event',
            'MenuItem',
            name,
          ),
        )
      } else {
        this.TrackingUtil.trackEvent('HeaderMenu', 'Click', name)
      }
    },
    login() {
      this.tracking('login')
      userState.unsetUser(this.instance)
      this.$store.commit('instancesState/setToken', null)

      if (this.backendConfig.defaults?.forceLoginPage || (localStorage.getItem('isApp') === 'true' && this.backendConfig.defaults?.forceLoginPageApp)) {
        this.$router.push(utils.getRouteWithDefaultParams('LoginPage'))
        return
      }
      const instanceConfig = config.getInstanceConfig(this.instance)
      let returnPath = this.$route.path
      if (
        this.$route.name === 'AuthPage'
        || this.$route.name === 'NoaccessPage'
      ) {
        returnPath = `/Home/${this.instance}/${this.event}/${this.language}`
      }

      const redirectUri = new URL(window.location.origin + returnPath)
      if (this.backendConfig.SSOLogin && Object.prototype.hasOwnProperty.call(instanceConfig, 'sso')) {
        const alternativePath = this.backendConfig?.SSOReturnUrl ? this.backendConfig.SSOReturnUrl : null
        const { identityUrl } = instanceConfig
        const loginUrl = instanceConfig.sso.url
        const prevQuery = utils.queryString(this.$route.query)
        const defaultParams = {
          shortname: this.event,
          language: this.language,
          returnUrl: window.location.origin + (alternativePath || this.$route.path)+'?'+prevQuery,
        }
        const defaultQueryStrings = utils.queryString(defaultParams)

        if (Object.prototype.hasOwnProperty.call(instanceConfig.sso, 'context')) {
          Object.assign(defaultParams, { context: instanceConfig.sso.context })
        }
        if (Object.prototype.hasOwnProperty.call(instanceConfig.sso, 'provider')) {
          Object.assign(defaultParams, { provider: instanceConfig.sso.provider })
        }

        let SSOUrl = `${identityUrl + loginUrl}?${defaultQueryStrings}`
        
        
        if(this.externalAuth) {
          const externalLoginUrl = this.backendConfig.defaults.externalAuth.loginUrl
          const externalLoginParams = {
            returnUrl: window.location.origin + (alternativePath || this.$route.path) +'?'+prevQuery,
          }
          const externalLoginQueryStrings = utils.queryString(externalLoginParams)
          SSOUrl = `${externalLoginUrl}${externalLoginUrl.includes('returnUrl') ? '' : '&'+externalLoginQueryStrings}`
        }
        
        localStorage.setItem('externalLogin', true)

        window.location.href = SSOUrl
        return
      }

      const params = {
        returnUrl: redirectUri,
      }
      const queryString = utils.queryString(params)
      const url = `${instanceConfig.webformsUrl}/Home/GetToken?${queryString}`
      localStorage.setItem('tryLogin', '123')
      if (!config.getInstanceConfig(this.instance).identityUrl) {
        utils.redirectOAuthAuthorize(this.$route)
        return
      }

      if (this.externalAuth) {
        if (this.backendConfig.defaults.externalAuth.loginUrl) {
          // window.location.href = `${webFormsUrl + logoutUrl}?${queryStrings}`
          window.location.href = this.backendConfig.defaults.externalAuth.loginUrl
          return
        }
      }
      if (this.$route.name !== 'LoginPage') {
        window.location.href = url
      }
    },
    logout() {
      this.tracking('logout')
      this.$cookies.remove('accesOnDays')
      this.$cookies.remove('tickets')
      this.$cookies.remove('hcp')
      userState.unsetUser(this.instance)
      this.$store.commit('instancesState/setToken', null)
      localStorage.removeItem('externalLogin')
      localStorage.removeItem('recheckedUserTicket')
      const webFormsUrl = config.getInstanceConfig(this.instance).webformsUrl
      const params = {
        eventName: this.event,
      }
      const queryStrings = utils.queryString(params)
      let logoutUrl = '/Main/Logout'

      if (this.backendConfig.defaults.externalAuth) {
        if (this.backendConfig.defaults.externalAuth.logoutUrl) {
          logoutUrl = this.backendConfig.defaults.externalAuth.logoutUrl
          // window.location.href = `${webFormsUrl + logoutUrl}?${queryStrings}`
          window.location.href = logoutUrl
          return
        }
      }
      // this.$router.push(utils.getRouteWithDefaultParams('LoginPage'))
      window.location.href = `${webFormsUrl + logoutUrl}?${queryStrings}`
    },
    icon(icon) {
      // eslint-disable-next-line prefer-const
      let [first, ...rest] = icon.split('-')
      rest = rest.join('-')
      if (first && rest) {
        return [first, rest]
      }
      return ['fa', 'question']
    },
    navItemProps(item) {
      return {
        active: this.isActive(item),
      }
    },
    setActive(nr) {
      const element = this.$refs[`menu-item-${nr}`]
      if (element) {
        // element[0].classList.add('sub-active')
      }
    },
  },
}
</script>
<style lang="scss">
  .myarea-section__icon-image-path {
    height: 1em;
    overflow: visible;
    vertical-align: -0.125em;
  } 
</style>