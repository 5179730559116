import store from '~/store'
import utils from '../utilities/utils'


export default async function ({ next, router, to }) {
  const $route = to
  const instance = store.getters['instancesState/getInstance']
  const event = store.getters['instancesState/getEvent']

  let returnPage = `${$route.path}?${utils.queryString($route.query)}`
  if (!returnPage || $route.name === 'NoaccessPage') {
    returnPage = `/home/${instance}/${event}/${language}`
  }
  const redirectUri = window.location.origin + returnPage
  localStorage.setItem('vmxReturnUrl', redirectUri)
  if (event === 'eadvcongress2024' && localStorage.getItem('isTerminal') === 'true' && redirectUri.includes('registrationLanding')) {
    localStorage.setItem('vmxReturnUrl', 'https://eadv-virtual-lms.m-anage.com/eadv/eadvcongress2024/en-GB/registration?container=terminal')
  }

  next()
  
}
