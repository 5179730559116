<template v-if="networkingActive">
  <div
    id="networking-widget-v2"
    class="networking-widget-v2"
  />
</template>

<script>
import Vue from 'vue'
import VueCookies from 'vue-cookies'
import store from '~/store'

import { mapActions, mapGetters } from 'vuex'



Vue.use(VueCookies)
export default {
  inject: {
    TrackingUtil: {
      type: Function,
    },
  },
  computed: {
    ...mapGetters({
      user: 'user/user',
      vmUser: 'vmUser/vmUser',
      config: 'config/configForKey',
    }),
    networkingWidgetV2() {
      const c = this.config('app_config').configV2
      return 'networkingWidgetV2' in c.defaultV2 ? c.defaultV2.networkingWidgetV2 : false
    }, 
  },
  async mounted() {
    if(!this.networkingWidgetV2 ) return
    await store.dispatch('user/loadUser')
    await store.dispatch('vmUser/loadVmUser')

    await this.$loadScript(`https://d3klq1qh6r64da.cloudfront.net/api_uploads/vm-plugins/chat/feat/networking/mgChatPlugin.js`)
    await this.loadStylesheet(`https://d3klq1qh6r64da.cloudfront.net/api_uploads/vm-plugins/chat/feat/networking/mgChatPlugin.css`)
    const setupObj = {
      domSelector: "#networking-widget-v2", // DOM Element where plugin will be loaded (querySelector)
      // pageElements: ["#vm2-header-element"], // optional=> For calculating available space height 
      user: {// vm user object of currently logged in user
        id: this.vmUser.user_id,
        firstname: this.user.Firstname,
        surname: this.user.Surname,
        email: this.user.Email,
        manage_id: this.user.UserId,
        profile_image_url: this.user.ProfilePicturePath,
      },
      access_token: this.vmUser.access_token,// currently logged in user's access_token
      baseApi: "api-lb.virtual-meeting.net",
      eventId: this.vmUser.event_id,
      onSocketMsg: null, // optional=>function callback on new message 
    }
    mgChatApp.init(setupObj) 
  },
  methods: {
    loadStylesheet(url) {
      const ss = document.styleSheets
      for (let i = 0, max = ss.length; i < max; i += 1) {
        if (ss[i].href === url) {
          return
        }
      }
      const link = document.createElement('link')
      link.setAttribute('rel', 'stylesheet')
      link.setAttribute('href', url)
      document.head.appendChild(link)
    },
  },
}

</script>

<style lang="scss">

</style>
